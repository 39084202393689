
<template>
  <v-layout column>
    <v-layout justify-space-between>
      <span class="grey--text text--darken-2 headline">{{ $t('balance_adjustments.list_title') }}</span>
      <tooltip-button
        :text="$t('balance_adjustments.name')"
        :disabled="!accountId"
        :tooltipText="$t('guests.account_required')"
        @click="$router.push({ name: routeNames.balance_adjustment.name, params: { id: guest.id } })"
        color="primary"
        outlined
      />
    </v-layout>
    <transaction-list v-if="isPermissionActive('PAGINATE_TRANSACTIONS')"
      :listId="table"
      :list="adjustments"
      :refreshColumnDecoration="refreshColumnDecoration"
      @decorateColumn="decorateColumn"
      addonTransactions
      class="mt-2"
    />
    <v-alert v-else :value="true" color="warning" dark class="mt-3">{{ $t('balance_adjustments.no_list_transactions_permission') }}</v-alert>
  </v-layout>  
</template>

<script>
import permissionMixins from '@/mixins/Permission'
import paginationMixins from '@/mixins/Pagination'
import { transactionTypes } from '@/config'
import { mapState, mapGetters } from 'vuex'
import routeNames from '@/router/routes'
import Vue from 'vue'

export default {
  props: {
    guest: Object
  },
  data () {
    return {
      routeNames,
      table: 'BalanceAdjustments',
      refreshColumnDecoration: null,
      adjustments: []
    }
  },
  computed: {
    ...mapState(['transactions', 'pagination', 'packages']),
    ...mapGetters({
      manualPaymentMethodId: 'configuration/manualPaymentMethodId',
    }),
    accountId () {
      return this.guest ? this.guest.accountId : null
    },
    guestId () {
      return this.guest ? this.guest.id : null
    }
  },
  methods: {
    list () {
      if (this.isPermissionActive('PAGINATE_TRANSACTIONS')) {
        this.$store.commit('pagination/setLoading', { table: this.table, loading: true })
        this.$store.dispatch('transactions/list', {
          limit: this.tablePagination.pages.itemsPerPage,
          offset: (this.tablePagination.pages.page - 1) * this.tablePagination.pages.itemsPerPage,
          order: this.tablePagination.pages.descending ? 'ASC' : 'DESC',
          currencyIds: this.packages.list.map(p => p.tokenCurrencyId),
          paymentMethodIds: [this.manualPaymentMethodId],
          type: transactionTypes.PAYMENT,
          accountIds: [this.accountId],
          guestId: this.guestId
        }).then((response) => {
          this.refreshColumnDecoration = new Date()
          this.adjustments = response.list
          this.updatePagination(response)
        })
      }
    },
    decorateColumn (path) {
      switch (path) {
        case 'station.name':
          this.$store.dispatch('stations/list', {}).then((stations) => {
            this.adjustments = this.adjustments.map((adjustment) => {
              const station = stations.find(s => s.id === adjustment.stationId)
              return { ...adjustment, station }
            })
          })
        case 'operator.name':
          this.$store.dispatch('operators/list', {}).then((operators) => {
            this.adjustments = this.adjustments.map((adjustment) => {
              const operator = operators.find(o => o.id === adjustment.operatorId)
              return { ...adjustment, operator }
            })
          })
      }
    }
  },
  created () {
    this.setItemsPerPageOptions({ value: [10, 25, 50], table: this.table })
    this.list()
  },
  components: {
    TooltipButton: () => import('@/components/app-components/buttons/TooltipButton.vue'),
    TransactionList: () => import('@/components/view-components/TransactionList.vue')
  },
  mixins: [
    paginationMixins,
    permissionMixins
  ],
  name: 'GuestOrders'
}
</script>
